import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { i18n, t } = useTranslation();

  return (
    <div>
      <div className="footer">
        <div className="footer-section">
          <div>
            <h2>{i18n.t('footer:project')}</h2>
            <ul>
              <li> {i18n.t('footer:comingSoon')}</li>
              <li> {i18n.t('footer:comingSoon')}</li>
              <li> {i18n.t('footer:comingSoon')}</li>
              <li> {i18n.t('footer:comingSoon')}</li>
             
            </ul>
          </div>
          <div>
            <h2>Hizmetler</h2>
            <ul>
            <li> {i18n.t('footer:comingSoon')}</li>
            <li> {i18n.t('footer:comingSoon')}</li>
            <li> {i18n.t('footer:comingSoon')}</li>
            <li> {i18n.t('footer:comingSoon')}</li>
            </ul>
          </div>
          <div>
            <h2>{i18n.t('footer:getInTouch')}</h2>
            <ul>
              <li> {i18n.t('footer:officePhone')}: 0507 091 15 51</li>
              <li> {i18n.t('footer:mobilePhone')}: 0507 091 15 51</li>
              <li>{i18n.t('footer:email')}: orkun@orwys.com </li>
              <li>Melikşah Mah. Melikşah Cad. No:50 Daire:1</li>
              <li> MERAM / KONYA</li>
            </ul>
          </div>
          <div>
            <h2>{i18n.t('footer:comunity')}</h2>
            <ul>
              <li>Github</li>
              <li>İnstagram</li>
              <li>Whatsapp</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
